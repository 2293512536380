// animation

function animateFrom(elem, direction) {
  direction = direction || 1;
  let x = 0,
    y = direction * 100;
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  elem.classList.add("isActive");

  gsap.fromTo(
    elem,
    { x: x, y: y, autoAlpha: 0, opacity: 0 },
    {
      duration: 1.25,
      x: 0,
      y: 0,
      opacity: 1,
      autoAlpha: 1,
      ease: "power2.out",
      // overwrite: "auto",
    }
  );
}

//test
function hide(elem) {
  gsap.set(elem, { autoAlpha: 0 });
  elem.classList.remove("isActive");
}

document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger);

  gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    //  hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function () {
        animateFrom(elem);
      },
      once: true,
    });
  });
});

// gs_reveal ipsType_center
// gs_reveal gs_reveal_fromLeft
// gs_reveal gs_reveal_fromRight


const coins = document.querySelectorAll(".animate-svg");
coins.forEach((el) => {
  let path = el.querySelectorAll("path:not(.no-animation)");
  ScrollTrigger.create({
    trigger: el,
    onEnter: function () {
      TweenMax.staggerFrom(
        path,
        0.5,
        {
          opacity: 0,
          y: 50,
          ease: "back",
        },
        0.14
      );
    },
    // onEnterBack: function () {
    //   TweenMax.staggerFrom(
    //     path,
    //     0.3,
    //     {
    //       opacity: 0,
    //       y: 50,
    //       ease: "back",
    //     },
    //     0.1
    //   );
    // },
  });
});


// current page

function parseUri(str) {
  var o = parseUri.options,
    m = o.parser[o.strictMode ? "strict" : "loose"].exec(str),
    uri = {},
    i = 14;

  while (i--) uri[o.key[i]] = m[i] || "";

  uri[o.q.name] = {};
  uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
    if ($1) uri[o.q.name][$1] = $2;
  });

  return uri;
}

parseUri.options = {
  strictMode: false,
  key: [
    "source",
    "protocol",
    "authority",
    "userInfo",
    "user",
    "password",
    "host",
    "port",
    "relative",
    "path",
    "directory",
    "file",
    "query",
    "anchor",
  ],
  q: {
    name: "queryKey",
    parser: /(?:^|&)([^&=]*)=?([^&]*)/g,
  },
  parser: {
    strict:
      /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
    loose:
      /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/,
  },
};

let pathname = window.location.pathname;
let menuElem = document.querySelectorAll(
  "header nav a:not(.logo), footer a:not(.box-logo)"
);

menuElem.forEach((el) => {
  if (parseUri(el.getAttribute("href")).path == pathname) {
    el.classList.add("current-page");
  }
  if (
    parseUri(el.getAttribute("href")).path.includes("account") &&
    pathname == "/account/"
  ) {
    el.classList.add("current-page");
  }
});

// end current page

document.querySelector(".default_field_nickname")
  ? document
    .querySelector(".default_field_nickname")
    .addEventListener("keyup", (e) => {
      document.querySelector(".default_field_username").value =
        e.target.value;
    })
  : null;

if (document.querySelector(".login-remember")) {
  let elementAfter = document.createElement("div");
  elementAfter.classList.add("forgot-pass-wrap");
  elementAfter.innerHTML = `<a href="/password-reset/" class="forgot-pass">Forgot your password?</a>`;

  document.querySelector(".login-remember").after(elementAfter);
}

//  page ready animation

// document.addEventListener("DOMContentLoaded", function () {
//   document.querySelector("body")
//     ? document.querySelector("body").classList.add("page-ready")
//     : null;
// });

// window.addEventListener("beforeunload", (event) => {
//   document.querySelector("body")
//     ? document.querySelector("body").classList.remove("page-ready")
//     : null;
// });

// document.querySelector("body").classList.remove("page-ready")

//  end page ready animation


// feedback slider
$('.custom-slider').slick({
  dots: true,
  infinite: true,
  // speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  prevArrow: $('#slider-nav-prev'),
  nextArrow: $('#slider-nav-next'),
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    }
  ]
});
// feedback slider


// header translate buttons

// trp-language-wrap

document.querySelectorAll(".translate-block-title").forEach(el => {
  el.addEventListener("click", () => {
    el.closest(".translate-block")
      .querySelectorAll(".translate-block-content")
      .forEach(content => {
        content.classList.toggle("hide")
      })
  })
})

const changeLanguage = (lang) => {
  document.querySelector(`img[alt='${lang}']`)?.click();
  // en_US
  // de_DE
}

// end header translate buttons

// burger button logic
document.querySelectorAll(".burger-button").forEach(button => {
  button.addEventListener("click", () => {
    // button.forEach(el=> el.classList.toggle(""))
    document.querySelector("body")?.classList.toggle("menu-open")
  })
})
// 



// feedback slider
$('.col-tabs-wrap').slick({
  dots: true,
  arrows: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  appendDots: $(".col-tabs-navigation"),
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        // dots: false
      }
    }
  ]
});
// feedback slider

const tabsTitles = ["Recurring payments", "Cascade processing", "Mass payments", "One-click payments"]
document
  .querySelectorAll(".col-tabs-navigation .slick-dots li button")
  .forEach((dot, index) =>
    dot.textContent = tabsTitles[index]
  )

document
  .querySelectorAll('.wpcf7-form-control-wrap input, .wpcf7-form-control-wrap textarea')
  .forEach(input => {
    input.addEventListener('input', evt => {
      if (evt.target.value != "") {
        evt.target.classList.add("not-empty")
        evt.target.closest("p").classList.add("not-empty")
      } else {
        evt.target.classList.remove("not-empty")
        evt.target.closest("p").classList.remove("not-empty")
      }
    })
  })


document.querySelectorAll("#policy-js").forEach(el => {
  el.querySelector(".wpcf7-list-item-label").innerHTML = el.querySelector(".wpcf7-list-item-label").innerHTML.replace("Privacy Policy", `<a href="https://policies.google.com/privacy?hl=en">Privacy Policy</a>`).replace("Terms of Service", `<a href="https://policies.google.com/terms?hl=en">Terms of Service</a>`)
});


const contactMessage = (message, status) => {
  // status: "success" or "failed"

  let item = document.createElement("div");
  item.classList.add("popup-contact-item");
  item.classList.add(status);

  item.innerHTML = `
  <p class="body_bold">${message}</p>
  <button type="button" class="popup-item-close" onclick="closeContactMessage(this)">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
              d="M17.9998 5.99994C17.8123 5.81247 17.558 5.70715 17.2928 5.70715C17.0277 5.70715 16.7733 5.81247 16.5858 5.99994L11.9998 10.5859L7.41382 5.99994C7.22629 5.81247 6.97198 5.70715 6.70682 5.70715C6.44165 5.70715 6.18735 5.81247 5.99982 5.99994C5.81235 6.18747 5.70703 6.44178 5.70703 6.70694C5.70703 6.9721 5.81235 7.22641 5.99982 7.41394L10.5858 11.9999L5.99982 16.5859C5.81235 16.7735 5.70703 17.0278 5.70703 17.2929C5.70703 17.5581 5.81235 17.8124 5.99982 17.9999C6.18735 18.1874 6.44165 18.2927 6.70682 18.2927C6.97198 18.2927 7.22629 18.1874 7.41382 17.9999L11.9998 13.4139L16.5858 17.9999C16.7733 18.1874 17.0277 18.2927 17.2928 18.2927C17.558 18.2927 17.8123 18.1874 17.9998 17.9999C18.1873 17.8124 18.2926 17.5581 18.2926 17.2929C18.2926 17.0278 18.1873 16.7735 17.9998 16.5859L13.4138 11.9999L17.9998 7.41394C18.1873 7.22641 18.2926 6.9721 18.2926 6.70694C18.2926 6.44178 18.1873 6.18747 17.9998 5.99994Z"
              fill="#6E6C74" />
      </svg>
  </button>`;

  document.querySelector(".popup-contact").appendChild(item);

  setInterval(() => {
    item.classList.add("hide")
  }, 6000);

  setInterval(() => {
    item.remove();
  }, 10000);
}

const closeContactMessage = (el) => {
  el.closest(".popup-contact-item").classList.add("hide")
}


// contact form send/fails events
document.addEventListener('wpcf7mailsent', function (response) {
  contactMessage("Message has been sent successfully", "success")
}, false);

document.addEventListener('wpcf7invalid', function (response) {
  contactMessage("There was an error", "failed")
}, false);

// end contact form events

// types slider
$('.slider-types').slick({
  dots: true,
  arrows: false,
  infinite: true,
  // speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendDots: $(".slider-types-dots"),
  afterChange: (el) => {
    console.log(el);
  }
});

let currentSlide = 0;

document
  .querySelectorAll(".slider-types-dots li")
  .forEach(el => el.classList.remove("slick-active"))

document
  .querySelectorAll(`.slider-types-dots li:nth-child(${currentSlide + 1})`)
  .forEach(el => el.classList.add("slick-active"))

$('.slider-types').on('afterChange', function (event, slick, currentSlide) {
  document
    .querySelectorAll(".slider-types-dots li")
    .forEach(el => el.classList.remove("slick-active"))

  document
    .querySelectorAll(`.slider-types-dots li:nth-child(${currentSlide + 1})`)
    .forEach(el => el.classList.add("slick-active"))
});

let navTitleArray = ["Full Payment Page", "iFrame Integration", "Host 2 Host"]

document
  .querySelectorAll(".col-navigation.slider-types-dots .slick-dots li button")
  .forEach((el, index) =>
    el.textContent = navTitleArray[index]
  )
// types slider




// current block logic

document.querySelectorAll(".nav-content-title, .wp-block-heading").forEach(el => {
  let item = document.createElement("a");
  let hash = el.textContent.toLowerCase().replaceAll(" ", "-").replace("?", "")
  item.textContent = el.textContent;
  item.setAttribute("href", `#${hash}`);
  el.setAttribute("id", `${hash}`);

  document.querySelector(".nav-wrap")?.appendChild(item);
})

let currentTab = document.querySelector("#banner"),
  lastTab = document.querySelector("#banner");

document.addEventListener("scroll", () => {
  document.querySelectorAll(".nav-content-title").forEach((el) => {
    if (
      el.getBoundingClientRect().top < 100 &&
      el.getBoundingClientRect().top > 0
    ) {
      lastTab = el;
    }

    // console.log(lastTab != currentTab, lastTab, currentTab);

    if (lastTab != currentTab) {
      document.querySelectorAll(".nav-wrap a").forEach((el) => {
        el.classList.remove("current");
      });

      let idEl = lastTab.id + "";
      idEl = idEl.trim();

      // setTimeout(() => {
        document
          .querySelectorAll(`.nav-wrap a[href="#${idEl}"]`)
          .forEach((elem) => {
            elem.classList.add("current");
          });
      // }, 0);

      currentTab = lastTab;
    }
  });
});

// end current block logic